export default [
   {
      name: "École polytechnique fédérale de Lausanne ‐ EPFL",
      imageUrlDark:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Fepfl-logo-dark_1200x900.png?alt=media&token=54141d42-557e-4ad0-b1f6-126378064daf",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Fepfl-logo-main.webp?alt=media&token=ffa56fe9-60ba-464d-a681-98bea220bfb7",
      website: "https://www.epfl.ch/",
      dimensions: {
         width: 800,
         height: 233,
      },
   },
   {
      name: "ETH Zürich",
      imageUrlDark:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Feth-logo-dark_1200x900.png?alt=media&token=aeb9df8f-8c4a-488b-aba4-603b0b80a90f",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Feth-logo-main.webp?alt=media&token=12c5fe83-719d-4636-b55d-01251606e401",
      website: "https://ethz.ch/de.html",
   },
   {
      name: "University of Duisburg-Essen",
      imageUrlDark: "",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/university-logos%2Funi-duisburg-essen.png?alt=media&token=093030a0-0f6f-4466-9e7a-93db7477b45b",
      website: "https://www.uni-due.de/en/",
   },
   {
      name: "IMD Business School",
      imageUrlDark:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Fimd-logo-dark_1200x900.png?alt=media&token=1306688f-cf42-4218-a788-4973e1d65e2f",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Fimd-logo-main_1200x900.png?alt=media&token=1cd6f682-4752-4494-a198-d720924af891",
      website: "https://www.imd.org/",
   },
   {
      name: "University of Graz",
      imageUrlDark: "",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Funi-graz-logo-main.webp?alt=media&token=5f7278b4-a851-4386-b670-a9cfdf7eeaeb",
      website: "https://www.uni-graz.at/",
   },
   // {
   //    name: "RWTH Aachen University",
   //    imageUrlDark:
   //       "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Frwtu-logo-darkpng_1200x900.png?alt=media&token=10d138d7-a76c-41a7-a49b-7eef462e3dd8",
   //    imageUrlMain:
   //       "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Frwtu-logo-main.webp?alt=media&token=2bfa4d43-fd0d-48e3-9611-446fa574342a",
   //    website: "https://www.rwth-aachen.de/",
   // },
   {
      name: "Dresden University of Technology (Technische Universität Dresden)",
      imageUrlDark:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Ftu-dresen-logo-dark_1200x900.png?alt=media&token=28d58290-2b97-405f-9467-4079f82302dc",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Ftu-dresen-logo-main.webp?alt=media&token=29559028-ab61-4f84-86c1-48791294a761",
      website: "https://tu-dresden.de/",
   },
   {
      name: "Vienna University of Technology (Technische Universität Wien)",
      imageUrlDark:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Ftu-wien-logo-dark_1200x900.png?alt=media&token=082ddc6e-2513-4107-82d8-306af82d81d6",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Ftu-wien-logo-main_1200x900.png?alt=media&token=11c1982f-67e4-4270-9cc4-30483ffe1b14",
      website: "https://www.tuwien.at/",
   },
   {
      name: "Utrecht University",
      imageUrlDark:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Futrecht-logo-dark_1200x900.png?alt=media&token=26b02df7-7580-44ff-998d-5c9110835ac4",
      imageUrlMain:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Futrecht-logo-main.webp?alt=media&token=f67bc505-4e96-4a45-9290-4fe265879174",
      website: "https://www.uu.nl/",
   },
   // {
   //     name: "Imperial College London",
   //     imageUrlDark: "",
   //     imageUrlMain: "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Funiversity-logos%2Fimperial-logo-main.webp?alt=media&token=9734c14a-e4d8-4e87-9f14-5c929eea4939",
   //     website: "https://www.imperial.ac.uk/",
   // },
];
