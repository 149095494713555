export default [
   {
      rating: 0,
      title: "Interactive and fun events",
      reviewText:
         "Couldn’t be happier with our collaboration with the creative minds at CareerFairy – they are unique in terms of flexibility and implementation of useful features. The livestreaming platform itself is amazing, especially regarding the candidate experience. We had a lot of fun hosting our event series with 600 students.",
      avatarUrl:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/testimonial-avatars%2Fkristina.webp?alt=media&token=12032166-f2a0-456f-890c-afdd443a7f17",
      position: "Employer Branding Expert @Accenture Germany",
      name: "Kristina Neidert",
      companyUrl:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/testimonial-companies%2Faccenture.png?alt=media&token=743787d1-6ccc-4f60-9489-ac2fc42fdb89",
   },
   // {
   //    rating: 0,
   //    title: "Attract more relevant talents",
   //    reviewText:
   //       "As we are in need of many different talents we found with CareerFairy a great and user friendly platform that allows us to reach a wide audience - especially important in this uncertain times. We highly appreciate their straight-forward and flexible way of working.",
   //    avatarUrl:
   //       "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/testimonial-avatars%2Fcarolyn.webp?alt=media&token=b6213aed-2921-4c20-a07d-9a471bd14c6e",
   //    position: "Senior HR Marketing Specialist @KPMG Switzerland",
   //    name: "Carolyn Hoechle",
   //    companyUrl:
   //       "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/testimonial-companies%2Fkpmg.png?alt=media&token=3d03398b-3ebe-453b-9807-f581314f4357",
   // },
   {
      rating: 0,
      title: "Connect with more universities",
      reviewText:
         "CareerFairy enabled us to establish contact with students from many different universities.\n" +
         "We really enjoyed the interaction with students via the new live streaming technology. ",
      avatarUrl:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/testimonial-avatars%2Fsabina.webp?alt=media&token=ecfe5169-ab5d-4e5d-9c4a-9166d8d0a40a",
      position: "Dep. Head Personal Marketing @Swiss Federal Administration",
      name: "Sabina Marra",
      companyUrl:
         "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/testimonial-companies%2Fswiss-federal.webp?alt=media&token=fa4c7c45-7648-44aa-b764-7de07cad1ec6",
   },
];
